<template>
  <form>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="title !== 'update_service'">Добавить сервис рекомендаций</p>
        <p class="modal-card-title" v-if="title === 'update_service'">Редактировать сервис рекомендаций</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body" style="display: flex">
        <b-field label="Название сервиса">
          <b-input type="text" v-model="name" placeholder="Введите название" required />
        </b-field>

        <b-field label="Тип алгоритма" style="margin-left: 20px">
          <b-select @input="setDefaultServiceName" v-model="selectedAlgType" :disabled="disabled" required>
            <option v-for="alg in algorithm" :value="alg.type" :key="alg.type">
              {{ alg.type }}
            </option>
          </b-select>
        </b-field>
      </section>

      <section class="modal-card-body" style="display: flex; flex-direction: column">
        <div
          v-if="selectedAlgType === 'top_offers' || selectedAlgType === 'matrix'"
          style="padding-bottom: 20px; margin-bottom: 20px; border-bottom: 1px solid"
        >
          <div style="display: flex; flex-direction: column; margin-bottom: 6px">
            <b-checkbox v-model="topOffersPeriod.active">{{selectedAlgType === 'top_offers' ? 'Интервал' : 'Интервал top_offers'}}</b-checkbox>

            <div v-if="topOffersPeriod.active" style="margin-bottom: 10px">
              <b-field label="Минуты:" style="margin-top: 15px">
                <b-input v-model="topOffersPeriod.value" type="number" min="1" step="1" />
              </b-field>
            </div>
          </div>

          <b-field v-if="selectedAlgType === 'top_offers'">
            <b-checkbox v-model="topOffersByCategories">
              Рекомендовать в рамках категории
            </b-checkbox>
          </b-field>
        </div>

        <b-field v-if="selectedAlgType === 'last_viewed'">
          <b-checkbox v-model="hidePurchasedItems">
            Скрывать купленные товары
          </b-checkbox>
        </b-field>

        <b-field>
          <b-checkbox v-model="availableOnly">Рекомендовать только доступные товары</b-checkbox>
        </b-field>

        <div style="display: flex; flex-direction: column; margin-bottom: 6px">
          <b-checkbox v-model="filtersByOrdersName.active"
            >Фильтрация товаров по названию</b-checkbox
          >

          <div
            v-if="filtersByOrdersName.active"
            style="display: flex; flex-direction: column; padding-bottom: 10px"
          >
            <b-field label="Коэффициент:" style="margin-top: 15px">
              <b-input type="number" min="0" step="0.01" />
            </b-field>

            <b-field label="Названия товаров:" style="margin-top: 15px">
              <b-input
                v-model="filtersByOrdersName.keywords"
                type="textarea"
                minlength="0"
                placeholder="Название товаров через запятую"
              />
            </b-field>
          </div>
        </div>

        <div>
          <b-checkbox v-model="filtersByPrice.active"
            >Фильтрация товаров по сходству в цене</b-checkbox
          >

          <div v-if="filtersByPrice.active" style="display: flex; flex-direction: column">
            <b-field label="Коэффициент:" style="margin-top: 15px">
              <b-input type="number" min="0" step="0.01" />
            </b-field>

            <b-field label="Рекомендованная цена/текущая цена:" style="margin-top: 15px">
              <b-input type="number" min="0" step="0.01" />
            </b-field>
          </div>
        </div>
        <b-field label="Категории для исключения">
          <b-input type="text" v-model="excludedCategories"  placeholder="Введите категории через запятую"/>
        </b-field>
      </section>

      <footer class="modal-card-foot" style="display: flex; justify-content: flex-end">
        <b-button label="Отменить" @click="$emit('close')" />
        <b-button v-if="title !== 'update_service'" @click="addService" label="Запуск" type="is-primary" />
        <b-button v-if="title === 'update_service'" @click="updateService" label="Обновить" type="is-primary" />
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: 'addService',
  props: {
    shopId: String,
    disabled: Boolean,
    availableOnlyProps: Boolean,
    topOffersByCategoriesProps: Boolean,
    selectedAlgTypeProps: String,
    topOffersPeriodProps: Number,
    hidePurchasedItemsProps: Boolean,
    serviceNameProps: String,
    excludedCategoriesProps: Array,
    title: String,
  },
  data() {
    return {
      name: '',
      algorithm: [
        {
          type: 'matrix',
          name: 'main_service'
        },
        {
          type: 'cart_upsale',
          name: 'cart_upsale'
        },
        {
          type: 'offer_upsale',
          name: 'offer_upsale'
        },
        {
          type: 'image',
          name: 'image'
        },
        {
          type: 'search',
          name: 'search'
        },
        {
          type: 'top_offers',
          name: 'top_offers'
        },
        {
          type: 'buying_now',
          name: 'buying_now'
        },
        {
          type: 'similar_offers',
          name: 'similar_offers'
        },
        {
          type: 'last_viewed',
          name: 'last_viewed'
        }
      ],
      selectedAlgType: this.selectedAlgTypeProps ? this.selectedAlgTypeProps : 'matrix',
      availableOnly: this.availableOnlyProps ? this.availableOnlyProps : false,
      topOffersByCategories: this.topOffersByCategoriesProps ? this.topOffersByCategoriesProps : false,
      filtersByOrdersName: {
        active: false,
        coefficient: 0,
        keywords: ''
      },
      filtersByPrice: {
        active: false,
        coefficient: 0,
        targetPrice: 0
      },
      topOffersPeriod: {
        active: this.topOffersPeriodProps ? true : false,
        value: this.topOffersPeriodProps ? this.topOffersPeriodProps : 1,
      },
      hidePurchasedItems: this.hidePurchasedItemsProps ? this.hidePurchasedItemsProps : false,
      excludedCategories: '',
    }
  },
  mounted() {
    if(this.title !== 'update_service') {
      this.setDefaultServiceName()
    } else {
      this.name = this.serviceNameProps
    }
    if (this.excludedCategoriesProps) {
      this.excludedCategories = this.excludedCategoriesProps.join(', ')
    }
    
  },
  methods: {
    setDefaultServiceName() {
      this.name = this.algorithm.filter(el => el.type === this.selectedAlgType)[0].name
    },
    addService() {
      const data = {
        name: this.name,
        type: this.selectedAlgType,
        available_only: this.availableOnly,
        top_offers_by_categories: this.topOffersByCategories,
        middleware: []
      }

      if (this.selectedAlgType === 'last_viewed') {
        data.params = { hide_bought_items: this.hidePurchasedItems }
      }

      if (this.topOffersPeriod.active) {
        data.top_offers_period = this.topOffersPeriod.value
      }

      if (this.filtersByOrdersName.active) {
        data.middleware.push({
          name: 'name_priority_middleware',
          params: {
            coefficient: this.filtersByOrdersName.coefficient.toString(),
            keywords: this.filtersByOrdersName.keywords
              .trim()
              .split(',')
              .map(el => el.trim())
          }
        })
      }

      if (this.filtersByPrice.active) {
        data.middleware.push({
          name: 'price_similarity_middleware',
          params: {
            target_price: this.filtersByPrice.targetPrice.toString(),
            coefficient: this.filtersByPrice.coefficient.toString()
          }
        })
      }

      this.$emit('addService', { data, shopId: this.$props.shopId })
      this.$emit('close')
    },
    updateService() {
      const data = {
        name: this.name,
        available_only: this.availableOnly,
        excluded_categories: [],
        middleware: []
      }

      if (this.selectedAlgType === 'last_viewed') {
        data.params = { hide_bought_items: this.hidePurchasedItems }
      }

      if(this.selectedAlgType === 'top_offers' || 'matrix') {
          data.top_offers_period = 0
          if (this.topOffersPeriod.active && this.selectedAlgType === 'top_offers') {
            data.top_offers_period = this.topOffersPeriod.value
            data.top_offers_by_categories = this.topOffersByCategories
          }else if(this.topOffersPeriod.active && this.selectedAlgType === 'matrix'){
            data.top_offers_period = this.topOffersPeriod.value
          }
      }

      if (this.filtersByOrdersName.active) {
        data.middleware.push({
          name: 'name_priority_middleware',
          params: {
            coefficient: this.filtersByOrdersName.coefficient.toString(),
            keywords: this.filtersByOrdersName.keywords
              .trim()
              .split(',')
              .map(el => el.trim())
          }
        })
      }

      if (this.filtersByPrice.active) {
        data.middleware.push({
          name: 'price_similarity_middleware',
          params: {
            target_price: this.filtersByPrice.targetPrice.toString(),
            coefficient: this.filtersByPrice.coefficient.toString()
          }
        })
      }

      if(this.excludedCategories.length) {
        data.excluded_categories = this.excludedCategories
          .trim()
          .split(',')
          .map(el => el.trim())
          .filter(Boolean)
      }

      this.$emit('updateService', { data, shopId: this.$props.shopId, serviceName: this.$props.serviceNameProps })
      this.$emit('close')
    }
  }
}
</script>
