<template>
  <form>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Добавить сайт</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Domain">
          <b-input type="text" v-model="domain" placeholder="Имя домена" required />
        </b-field>

        <b-field label="ID магазина">
          <b-input type="text" v-model="shopId" placeholder="ID магазина" required />
        </b-field>
      </section>
      <footer class="modal-card-foot" style="display: flex; justify-content: space-between">
        <b-button label="Отменить" @click="$emit('close')" />
        <b-button :disabled="addButtonDisabled" @click="addSite" label="Добавить" type="is-primary" />
      </footer>
    </div>
  </form>
</template>

<script>
  export default {
    name: 'addSiteForm',
    data() {
      return {
        domain: '',
        shopId: ''
      }
    },
    computed: {
      addButtonDisabled () {
        return !this.domain || !this.shopId
      }
    },
    methods: {
      addSite() {
        this.$emit('addSite', {domain: this.domain, shopId: this.shopId})
        this.$emit('close')
      }
    }
  }
</script>

<style scoped></style>
