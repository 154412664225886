<template>
  <section class="recommendations">
    <div class="control-panel">
      <div class="search">
        <b-input
          v-model="searchValue"
          :placeholder="`${$t('search')}...`"
          type="search"
          icon-pack="fas"
          icon="search"
        />
      </div>

      <div class="buttons">
        <b-button
          @click="confirmRestartServices"
          :label="$t('recommendations.restart_services')"
          type="is-primary"
          icon-left="reload"
          outlined
        />
        <b-button
          @click="addSiteHandler"
          :label="$t('recommendations.add_site')"
          type="is-primary"
          icon-left="plus"
          outlined
        />
      </div>
    </div>

    <b-table
      :data="data"
      ref="table"
      detailed
      hoverable
      custom-detail-row
      :default-sort="['domain', 'asc']"
      detail-key="domain"
      :show-detail-icon="true"
    >
      <template slot-scope="props">
        <b-table-column field="domain" :label="columnsVisible['domain'].title" sortable>
          <template>
            <a @click="toggle(props.row)">
              {{ props.row.domain }}
            </a>
          </template>
        </b-table-column>

        <b-table-column
          field="serviceCount"
          :label="columnsVisible['serviceCount'].title"
          sortable
          centered
        >
          {{ props.row.serviceCount }}
        </b-table-column>

        <b-table-column
          field="serviceActiveCount"
          :label="columnsVisible['serviceActiveCount'].title"
          sortable
          centered
        >
          {{ props.row.serviceActiveCount }}
        </b-table-column>

        <b-table-column :label="columnsVisible['newService'].title" centered>
          <div @click="addServiceHandler(props.row.shopId)">
            <b-icon class="icon-btn" icon="plus" type="is-primary" />
          </div>
        </b-table-column>
        <b-table-column :label="columnsVisible['deleteSite'].title" centered>
          <div @click="confirmDeleteSite(props)">
            <b-icon class="icon-btn" icon="delete" type="is-primary" />
          </div>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <tr>
          <td colspan="5" class="active-detail-row">
            <b-table :data="props.row.items" class="sub-table">
              <template slot-scope="props">
                <b-table-column
                  field="name"
                  :label="columnsVisibleDetails['name'].title"
                  width="300"
                  sortable
                >
                  {{ props.row.name }}
                </b-table-column>

                <b-table-column
                  field="name"
                  :label="columnsVisibleDetails['algorithm'].title"
                  sortable
                  centered
                >
                  {{ props.row.algorithm }}
                </b-table-column>

                <b-table-column
                  field="serviceCount"
                  :label="columnsVisibleDetails['address'].title"
                  sortable
                  centered
                >
                  {{ props.row.address }}
                </b-table-column>

                <b-table-column :label="columnsVisibleDetails['created'].title" centered>
                  <span>
                    {{ props.row.created | dateTime }}
                  </span>
                </b-table-column>
                <b-table-column :label="columnsVisibleDetails['controls'].title" centered>
                  <div class="controls">
                    <div @click="updateServiceSettingsHandler({
                        shopId:props.row.shopId, 
                        serviceName: props.row.name, 
                        disabled:true, 
                        algorithm:props.row.algorithm
                      })">
                      <b-icon class="icon-btn" icon="settings" size="is-small" type="is-primary" />
                    </div>
                    <div
                      @click="
                        confirmUpdateService({
                          serviceName: props.row.name,
                          shopId: props.row.shopId,
                          active: props.row.active
                        })
                      "
                    >
                      <b-icon
                        class="icon-btn"
                        :icon="props.row.active ? 'stop' : 'play'"
                        type="is-primary"
                      />
                    </div>

                    <div @click="confirmDeleteService({serviceName: props.row.name, shopId: props.row.shopId})">
                      <b-icon class="icon-btn" icon="delete" size="is-small" type="is-danger" />
                    </div>
                  </div>
                </b-table-column>
              </template>
            </b-table>
          </td>
        </tr>
      </template>
    </b-table>

    <b-loading :is-full-page="true" :active="loading" :can-cancel="false" />
  </section>
</template>

<script>
  import addSiteForm from '@/components/recommendations/addSiteForm'
  import addService from '@/components/recommendations/addService'
  import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'Recommendations',
    data() {
      return {
        columnsVisible: {
          domain: { title: 'Домен' },
          serviceCount: { title: 'Всего сервисов' },
          serviceActiveCount: { title: 'Сервисов запущено' },
          newService: { title: 'Новый сервис' },
          deleteSite: { title: 'Удалить сайт' }
        },
        columnsVisibleDetails: {
          name: { title: 'Название сервиса' },
          algorithm: { title: 'Тип алгоритма' },
          address: { title: 'Адрес сервиса' },
          created: { title: 'Создан' },
          controls: { title: 'Управление' }
        },
        searchValue: ''
      }
    },
    watch: {
      searchValue() {
        this.setSearchValue(this.searchValue.trim())
      }
    },
    async mounted() {
      await this.GET_RECOMMENDATIONS_LIST()
    },
    computed: {
      ...mapGetters('recommendations', ['data']),
      ...mapState('recommendations', ['loading'])
    },
    methods: {
      ...mapActions('recommendations', [
        'GET_RECOMMENDATIONS_LIST',
        'RESTART_ALL_SERVICES',
        'UPDATE_SERVICE',
        'DELETE_SERVICE',
        'ADD_NEW_SITE',
        'CREATE_NEW_SERVICE',
        'DELETE_SITE',
        'GET_SERVICE_INFO',
        'UPDATE_SERVICE_SETTINGS'
      ]),
      ...mapMutations('recommendations', ['setSearchValue']),
      addSiteHandler() {
        this.$buefy.modal.open({
          parent: this,
          component: addSiteForm,
          hasModalCard: true,
          trapFocus: true,
          events: {
            addSite: this.addSite
          }
        })
      },
      addSite({ domain, shopId }) {
        this.ADD_NEW_SITE({ domain, shopId })
      },
      addServiceHandler(shopId) {
        this.$buefy.modal.open({
          parent: this,
          component: addService,
          hasModalCard: true,
          trapFocus: true,
          props: {
            shopId,
          },
          events: {
            addService: this.addService
          }
        })
      },
      async updateServiceSettingsHandler(data) {
        let response = await this.GET_SERVICE_INFO({shopId: data.shopId, serviceName: data.serviceName})
        
        this.$buefy.modal.open({
          parent: this,
          component: addService,
          hasModalCard: true,
          trapFocus: true,
          props: {
            shopId: data.shopId,
            serviceNameProps: data.serviceName,
            disabled: data.disabled,
            selectedAlgTypeProps: response.alg_type,
            availableOnlyProps: response.available_only,
            topOffersByCategoriesProps: response.top_offers_by_categories,
            topOffersPeriodProps: response.top_offers_period,
            excludedCategoriesProps: response.excluded_categories,
            hidePurchasedItemsProps: response.params.hide_bought_items ? response.params.hide_bought_items : false,
            title: 'update_service'
          },
          events: {
            updateService: this.updateService
          }
        })
      },
      addService ({data, shopId}) {
        this.CREATE_NEW_SERVICE({data, shopId})
      },
      updateService({data, shopId, serviceName}) {
        this.UPDATE_SERVICE_SETTINGS({data, shopId, serviceName})
      },
      toggle(row) {
        this.$refs.table.toggleDetails(row)
      },
      confirmDeleteSite(data) {
        this.$buefy.dialog.confirm({
          message: `Вы уверены, что хотите <b>удалить</b> сайт - ${data.row.domain} ?`,
          confirmText: 'Удалить сайт',
          type: 'is-danger',
          hasIcon: true,
          icon: 'delete',
          cancelText: 'Отменить',
          onConfirm: async () => await this.DELETE_SITE(data.row.shopId)
        })
      },
      confirmDeleteService(data) {
        this.$buefy.dialog.confirm({
          message: `Вы уверены, что хотите <b>удалить</b> сервис - ${data.serviceName}?`,
          confirmText: 'Удалить сервис',
          type: 'is-danger',
          hasIcon: true,
          icon: 'delete',
          cancelText: 'Отменить',
          onConfirm: async () => await this.DELETE_SERVICE(data)
        })
      },
      confirmUpdateService(data) {
        this.$buefy.dialog.confirm({
          message: `Вы уверены, что хотите  <b>${
            data.active ? 'остановить' : 'запустить'
          }</b> сервис - ${data.serviceName}?`,
          confirmText: data.active ? 'Остановить сервис' : 'Запустить сервис',
          type: 'is-primary',
          hasIcon: true,
          icon: data.active ? 'stop' : 'play',
          cancelText: 'Отменить',
          onConfirm: async () => await this.UPDATE_SERVICE(data)
        })
      },
      confirmRestartServices() {
        this.$buefy.dialog.confirm({
          message: `Вы уверены, что хотите <b>перезапустить</b> все активные сервисы?`,
          confirmText: 'Перезапустить сервисы',
          type: 'is-primary',
          hasIcon: true,
          icon: 'restart',
          cancelText: 'Отменить',
          onConfirm: async () => await this.RESTART_ALL_SERVICES()
        })
      }
    }
  }
</script>

<style lang="scss">
  .recommendations {
    .active-detail-row {
      border-left: 3px solid #7957d5;
      background-color: rgba(121, 87, 213, 0.05);
    }

    .sub-table table.table {
      background-color: rgba(121, 87, 213, 0);
    }

    .control-panel {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .search {
      max-width: 300px;
    }

    .controls {
      display: flex;
      justify-content: center;
    }

    .icon-btn {
      cursor: pointer;
    }

    .icon-btn:hover {
      transform: scale(1.6);
    }
  }
</style>
